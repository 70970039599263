import {GetterTree, MutationTree, ActionTree} from 'vuex'
import {apiClient} from "@/api/client";
import {getLang} from "@/utils/lang";
import {keyErrors, keyErrors as notificationKeys} from "@/notifications";
import {getEndpoint} from "@/utils/endpoints";
import {openSocket} from "@/socket";
import {ClientSocket, OWL, PURL} from "~/shared";

type ChangeModelVersion = {
  endpoint: string,
  attributes: {
    Name: string,
    Version: string,
    Description: string
  }
}

const _lang = {
  'RU': {
    modelBlockBy: 'Модель заблокирована для редактирования пользователем:',
  },
  'EN': {
    modelBlockBy: 'The model is locked for user editing:',
  },
}
let webSocket: ClientSocket = null

export const isModelBlock = (versions) => {
  // const versions = this.$store.state.ontologyVersions
  if (versions.isOn) {
    if (versions.access) {
      if (!versions.modelIsBlock) {
        return versions.modelIsBlockByUser
      }
      return versions.modelIsBlock
    }
    else {
      return true
    }
  }
  else {
    return false
  }
}

class State {
  /**
   * Флаг загрузки данных о версионировании
   * */
  isLoad = true

  /**
   * Флаг включенного функционала
   * */
  isOn = false
  /**
   * Флаг доступа к версионированию для данной точки
   * */
  access: boolean = false
  lastVersion = null
  viewVersion = null
  versions = []
  /**
   * Флаг блокировки модели
   * */
  modelIsBlock = false
  /**
   * Флаг блокировки модели с учетом пользователя
   * */
  modelIsBlockByUser = false
  /**
   * Автор изменения модели
   * */
  contributor: null
  /**
   * Дата изменения модели
   * */
  date: null
  /**
   * Флаг наличия версии от МДМ в списке версий из БД
   * */
  isExistInList: false
}

let lang
let endpointId

const getters: GetterTree<State, any> = {}

const mutations: MutationTree<State> = {
  setLoad(state, payload) {state.isLoad = payload},
  setIsOn(state, payload) {state.isOn = payload},
  setAccess(state, payload) {state.access = payload.Access === 'editable'},
  setViewVersion(state, payload?) {
    if (payload) {
      state.viewVersion = payload.uri
    }
    else {
      state.viewVersion = null
    }
  },
  setLastVersion(state, payload) {
    if (!payload.Code) {
      this.commit('notifications/toast', {id: keyErrors.NoVersionInfo}, {root: true})
      return
    }
    const find = state.versions.filter(el => el.temporary === 0)
                      .find(el => el.uri.includes(payload.Code))
    if (find) {
      state.isExistInList = true
      state.lastVersion = find
    }
    else {
      state.isExistInList = false
      // this.commit('notifications/toast', {id: keyErrors.NoMatchVersionByCode, arg: payload.Code}, {root: true})
      state.lastVersion = {
        uri: payload.Code,
        name: payload.Attribute.find(el => el.AttributeId === PURL.title).Value
      }
    }
  },
  setVersions(state, payload) {
    state.versions = payload
  },
  setModelIsBlockAndContributor(state) {
    if (state.isOn) {
      const versions = state.versions
      const find = versions.find(el => el.temporary === 1)
      if (find) {
        state.modelIsBlock = false
        state.modelIsBlockByUser = this.state.auth.user.name !== find.contributor
        state.contributor = find.contributor
        state.date = find.modified_date
      }
      else {
        state.modelIsBlock = true
        state.modelIsBlockByUser = false
        state.contributor = null
        state.date = null
      }
    }
    else {
      state.modelIsBlock = false
      state.modelIsBlockByUser = false
      state.contributor = null
      state.date = null
    }
  },
  socketClose() {
    if (webSocket) {
      webSocket.close()
    }
  },
  clearVersionInfo(state) {
    state.access = null
    state.lastVersion = null
    state.viewVersion = null
    state.versions = []
    state.modelIsBlock = false
    state.contributor = null
    state.date = null
    state.isExistInList = false
  }
}

const actions: ActionTree<State, any> = {

  async init(context, payload?: string) {
    context.commit('setIsOn', context.rootState.constants.constants['use_data_model_version'])

    context.commit('setLoad', true)

    lang = getLang()

    endpointId = payload || await getEndpoint(context)
    webSocket = await openSocket(endpointId, lang)
    webSocket.subscribe('modelVersion', async ({action, user}) => {
      console.info('received action', {action, user})
      if (action.type === OWL.Ontology) {
        if (context.state.access) {
          context.commit('setLoad', true)
          await context.dispatch('handleVersions')
          if (action.action === 'rollbackVersion' || action.action === 'discardVersion') {
            context.commit('setViewVersion', null)
          }
          context.commit('setLoad', false)
        }
      }
    })

    if (context.state.isOn) {
      context.commit('setAccess', (await apiClient.get(`endpoints/${endpointId}/ontology_version/access`)).data)
      await context.dispatch('handleVersions')
    }
    else {
      context.state.modelIsBlock = false
      context.state.modelIsBlockByUser = false
      context.state.contributor = null
    }
    context.commit('setLoad', false)
  },

  async handleVersions(context) {
    context.commit('setVersions', (await apiClient.get(`endpoints/${endpointId}/ontology_versions`)).data)
    context.commit('setModelIsBlockAndContributor')
    context.commit('setLastVersion', (await apiClient.get(`endpoints/${endpointId}/ontology_version`)).data)
  },

  async block(context, payload: string) {
    context.commit('setLoad', true)
    lang = getLang()
    try {
      const response = (await apiClient.post(`/endpoints/${payload}/ontology_versions/block`)).data

      if (response.operations[0].result === 'error') {
        if (response.operations[0].message === 'Temporary version exists') {
          throw new Error(`${_lang[lang].modelBlockBy} ${context.state.contributor} ${context.state.date}`)
        }
        else {
          throw new Error(`operation result=${response.operations[0].message}`)
        }
      }

      await context.dispatch('handleVersions')
      context.commit('notifications/toast', {
        id: notificationKeys.OperationSuccess
      }, {root: true})
    } catch (e) {
      console.error(e)
      context.commit('notifications/toast', {
        id: notificationKeys.OperationError,
        arg: e.message
      }, {root: true})
    }

    context.commit('setLoad', false)
  },

  async save(context, payload: ChangeModelVersion) {
    context.commit('setLoad', true)

    try {
      const response = (await apiClient.post(`/endpoints/${payload.endpoint}/ontology_versions/save`, payload.attributes)).data

      if (response.operations[0].result === 'error') {
        throw new Error(`operation result=${response.operations[0].message}`)
      }

      await context.dispatch('handleVersions')
      context.commit('notifications/toast', {
        id: notificationKeys.OperationSuccess
      }, {root: true})
    } catch (e) {
      console.error(e)
      context.commit('notifications/toast', {
        id: notificationKeys.OperationError,
        arg: e.message
      }, {root: true})
    }

    context.commit('setLoad', false)
  },

  async rollback(context, payload?: string) {
    context.commit('setLoad', true)
    try {
      const response = (await apiClient.get(`/endpoints/${payload}/ontology_versions/rollback`)).data

      if (response.operations[0].result === 'error') {
        throw new Error(`operation result=${response.operations[0].message}`)
      }

      await context.dispatch('handleVersions')

      context.commit('notifications/toast', {
        id: notificationKeys.OperationSuccess
      }, {root: true})

    } catch (e) {
      console.error(e)
      context.commit('notifications/toast', {
        id: notificationKeys.OperationError,
        arg: e.message
      }, {root: true})
    }
    context.commit('setLoad', false)
  },

  async discard(context, payload?: string) {
    context.commit('setLoad', true)
    try {
      const response = (await apiClient.get(`/endpoints/${payload}/ontology_versions/discard`)).data

      if (response.operations[0].result === 'error') {
        throw new Error(`Operation result=${response.operations[0].message}`)
      }

      await context.dispatch('handleVersions')

      context.commit('notifications/toast', {
        id: notificationKeys.OperationSuccess
      }, {root: true})
    } catch (e) {
      context.commit('notifications/toast', {
        id: notificationKeys.OperationError,
        arg: e.message
      }, {root: true})
    }

    context.commit('setLoad', false)
  }
}

export default {
  namespaced: true,
  state: new State(),
  mutations: mutations,
  actions: actions,
  getters: getters,
}
